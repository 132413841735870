<h2 mat-dialog-title>臺中市科展</h2>
<mat-dialog-content class="mat-typography">
  <a href="https://www.fhbes.tc.edu.tw/latest_activity/1062" target="_blank">
    <h2>臺中市科展刷新紀錄</h2>
  </a>
  <a href="https://www.fhbes.tc.edu.tw/latest_activity/1004" target="_blank">
    <h2>臺中市科展十三連霸</h2>
  </a>

  <a href="https://www.fhbes.tc.edu.tw/latest_activity/958" target="_blank">
    <h2>臺中市科展十二連霸</h2>
  </a>

  <a href="https://www.fhbes.tc.edu.tw/latest_activity/867" target="_blank">
    <h2>臺中市科展十一連霸</h2>
  </a>
  <a href="https://www.fhbes.tc.edu.tw/latest_activity/810" target="_blank">
    <h2>臺中市科展十連霸</h2>
  </a>
  <a href="https://www.fhbes.tc.edu.tw/latest_activity/733" target="_blank">
    <h2>科展常勝軍9連霸 榮獲臺中市科展 國小組團體獎 第一名</h2>
  </a>

  <a href="https://www.fhbes.tc.edu.tw/latest_activity/655" target="_blank">
    <h2>臺中市科展八連霸</h2>
  </a>

  <a href="https://www.fhbes.tc.edu.tw/latest_activity/457" target="_blank">
    <h2>科展六連霸 全台手「腐」奪冠</h2>
  </a>

  <a href="https://www.fhbes.tc.edu.tw/latest_activity/288" target="_blank">
    <h2>明道普霖斯頓雙語小學科展連續五年奪冠</h2>
  </a>
  <a href="https://www.fhbes.tc.edu.tw/latest_activity/215" target="_blank">
    <h2>明道普霖斯頓雙語小學 連4年科展奪冠</h2>
  </a>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close (click)="closeDialog()">關閉</button>
</mat-dialog-actions>

