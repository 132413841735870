import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no1129',
  templateUrl: './no1129.component.html',
  styleUrls: ['./no1129.component.css']
})
export class No1129Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
