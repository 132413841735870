<h2 mat-dialog-title>太陽能科學創意玩具競賽</h2>
<mat-dialog-content class="mat-typography">

  <a href="https://www.fhbes.tc.edu.tw/latest_activity/976" target="_blank">
    <h2>111學年度台中市國小太陽能科學創意玩具競賽13連冠(中區首霸)</h2>
  </a>
  <a href="https://www.fhbes.tc.edu.tw/latest_activity/976" target="_blank">
    <h2>110學年度台中市國小太陽能科學創意玩具競賽第12度冠軍</h2>
  </a>

  <a href="https://www.fhbes.tc.edu.tw/latest_activity/907" target="_blank">
    <h2>109學年度台中市國小太陽能科學創意玩具競賽第11度冠軍</h2>
  </a>
  <a href="https://www.fhbes.tc.edu.tw/latest_activity/762" target="_blank">
    <h2>107學年度台中市國小太陽能科學創意玩具競賽第九度冠軍</h2>
  </a>
  <a href="https://www.fhbes.tc.edu.tw/latest_activity/619" target="_blank">
    <h2>105學年度台中市國小太陽能科學創意玩具競賽第七度冠軍</h2>
  </a>
  <a href="https://www.fhbes.tc.edu.tw/latest_activity/529" target="_blank">
    <h2>104學年度台中市國小太陽能科學創意玩具競賽第六度冠軍</h2>
  </a>
  <a href="https://www.fhbes.tc.edu.tw/latest_activity/472" target="_blank">
    <h2>103學年度臺中市太陽能科學創意玩具競賽五連霸</h2>
  </a>

  <a href="https://www.fhbes.tc.edu.tw/latest_activity/379" target="_blank">
    <h2>102學年度台中市國小太陽能科學創意玩具競賽第四度冠軍</h2>
  </a>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close (click)="closeDialog()">關閉</button>
</mat-dialog-actions>

