<h2 mat-dialog-title>自然探索隊</h2>
<mat-dialog-content class="mat-typography">
  <a href="https://www1.fhbes.tc.edu.tw/latest-activity/881" target="_blank">
    <h2>自然探索隊戶外教學之開心農場 - 大自然的美味廚房</h2>
  </a>
  <a href=" https://www.fhbes.tc.edu.tw/latest_activity/942" target="_blank">
    <h2>自然探索隊：一日布農文化體驗</h2>
  </a>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close (click)="closeDialog()">關閉</button>
</mat-dialog-actions>

