import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-science-expo',
  templateUrl: './science-expo.component.html',
  styleUrls: ['./science-expo.component.css']
})
export class ScienceExpoComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ScienceExpoComponent>,

  ) { }

  ngOnInit(): void {
  }

  closeDialog(): void {
    this.dialogRef.close();
  }


}
