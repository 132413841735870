<div fxFlexFill
     class="container"
     fxLayout="column">
  <div fxFlex="auto">
    <div class="title-img">
      <img src="assets/images/latest-activity/no884/title.jpg" alt="title img"/>
    </div>
  </div>
  <div fxFlex="auto" class="title-ch-font">
    <span>108學年度 校園服務隊社會服務-古道淨山擁抱次森林</span>
  </div>
  <div fxFlex="auto" style="font-style: italic; text-indent : 2em ;">
    <span>
      校園服務隊是專屬於高年級的選拔校隊，也是學校榮譽及紀律的代表性隊伍，更肩負每日校內升旗儀式、朝會護旗進場等重要事項。為此學校特別為校園服務隊規劃了「校園服務隊領袖培訓課程」，以建立隊員間榮譽及向心力，並透過不間斷的基本儀態訓練，讓動作更為熟練。除了訓練課程，每學年規劃生態導覽及社會服務活動，讓隊員在愛校服務之餘更成為愛地球小尖兵，了解生態進而保護生態，讓社會服務能說到、做到還能學到，貢獻自己的心力來服務社會，讓學習環境更美好。
    </span>
  </div>

  <div fxFlex="auto">
    <span class="article-subscript-title">【探索次森林-發覺大自然的美好】</span>
  </div>
  <div fxFlex="auto">
    <div class="article-subscript-content">
      <img src="assets/images/latest-activity/no884/01.jpg"
           style="height:auto; float: right; margin:10px;"
           [ngClass]="'img-30'"
           [ngClass.sm]="{'img-sm': true}"
           [ngClass.xs]="'img-xs'"
           alt="創辦人烤披薩"/>
      今年在國內疫情逐漸趨緩，防疫逐步解封的帶動下，決定帶校園服務隊到彰化社頭來一場「古道淨山擁抱次森林」的環境生態教育。當天七點不到，校園服務隊就整裝待發踏上生態之旅，在資深導覽講師陳美娟老師的帶領下，隊員們穿上背心、戴上手套、拿著長夾，走進舊稱「挑鹽古道」的十八彎古道進行淨山護山活動。走進十八彎古道的我們，彷彿進入了大自然的寶庫，步道雖是經人工干擾再經演化的次森林，但豐富的生態讓我們大開眼界。一進步道就有俗稱「花和尚」的五色鳥以清脆的叫聲迎接我們，沿途更有紫斑蝶、紅肩粉蝶為我們開道；而攀木蜥蜴則陪伴我們左右一同探索。植物就更是多元，學了才知道常見的阿勃勒雖列為可食用，但誤吃可是會中毒的；
      <img src="./assets/images/latest-activity/no884/02.jpg"
           style="height:auto; float: left; margin:10px;"
           [ngClass]="'img-30'"
           [ngClass.sm]="{'img-sm': true}"
           [ngClass.xs]="'img-xs'"
           alt="小孩吃披薩">
      以前常將木材作為黑板的黑板樹；桃花心木種子會飛舞，但你知道葉子是不對襯的嗎？還有又名紙鈔樹、鹿仔樹的構樹，原來果實甜度不輸蔗糖，連螞蟻都難以抗拒。最重要是了解到許多外來種的植物，不僅影響本土植物的生存空間，更造成環境的危害。另外，也從姑婆芋的生長以及榕樹三種根部，學習到大自然的生存之道。隊員們一邊淨山，撿拾步道間的垃圾，一邊向大自然學習，這樣的體驗我想是前所未有的。
    </div>
  </div>

  <div fxFlex="auto">
    <div fxFlexFill fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="1%">
      <div fxFlex="25">
        <img src="./assets/images/latest-activity/no884/03.jpg"
             alt="小孩烤Pizza" style="width:100%; height:auto;"/>
      </div>
      <div fxFlex="25">
        <img src="./assets/images/latest-activity/no884/04.jpg"
             alt="小孩烤Pizza" style="width:100%; height:auto;"/>
      </div>
      <div fxFlex="25">
        <img src="./assets/images/latest-activity/no884/05.jpg"
             alt="小孩烤Pizza" style="width:100%; height:auto;"/>
      </div>
      <div fxFlex="25">
        <img src="./assets/images/latest-activity/no884/06.jpg"
             alt="小孩烤Pizza" style="width:100%; height:auto;"/>
      </div>
    </div>
  </div>
  <div fxFlex="auto">
    <div fxFlexFill fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="1%">
      <div fxFlex="25">
        <img src="./assets/images/latest-activity/no884/07.jpg"
             alt="小孩烤Pizza" style="width:100%; height:auto;"/>
      </div>
      <div fxFlex="25">
        <img src="./assets/images/latest-activity/no884/08.jpg"
             alt="小孩烤Pizza" style="width:100%; height:auto;"/>
      </div>
      <div fxFlex="25">
        <img src="./assets/images/latest-activity/no884/09.jpg"
             alt="小孩烤Pizza" style="width:100%; height:auto;"/>
      </div>
      <div fxFlex="25">
        <img src="./assets/images/latest-activity/no884/10.jpg"
             alt="小孩烤Pizza" style="width:100%; height:auto;"/>
      </div>
    </div>
  </div>
  <div fxFlex="auto">
    <div style="margin-top: 10px; margin-bottom:10px;">
      <span class="article-subscript-title">【靜心淨心-清水春光】</span>
    </div>
    <div class="article-subscript-content">
      <img src="./assets/images/latest-activity/no884/11.jpg"
           style="height:auto; float: left; margin:10px;"
           [ngClass]="'img-25'"
           [ngClass.sm]="{'img-sm': true}"
           [ngClass.xs]="'img-xs'"
           alt="小孩吃披薩">
      經過了山林的生態洗禮，回到清水巖寺進行古蹟導覽，讓所有隊員「靜心也淨心」。被列為三級古蹟的清水巖寺，迄今已有兩百七十多年的歷史，又因地出泉水、風光明媚而有「清水春光」的美名，更是彰化八景之一。這泉水來頭可不小，相傳清朝嘉慶君遊台灣時，曾在清水岩挑鹽古道飲甘泉，讚不絕口，而博得「甘露泉」之美名，和台北北投「長壽泉」、台東知本「美人泉」並稱台灣三大名泉。隊員看到清涼的泉水，無不歡天喜地，用泉水將淨山後的疲憊一「沖」而散
      當天很幸運的遇到彰化文化隊利用自然栽種法所種植的水稻要收割，也讓隊員們體驗古法栽種與大自然共存的生存法則。
    </div>
  </div>
  <div fxFlex="auto">
    <div fxFlexFill
         fxLayout="row"
         fxLayout.sm="column"
         fxLayout.xs="column"
         fxLayoutGap="1%"
    >
      <div fxFlex="25">
        <div fxFlexFill fxLayout="column">
          <div fxFlex="100">
            <img src="./assets/images/latest-activity/no884/12.jpg"
                 alt="小孩烤Pizza" style="width:100%; height:auto;"/>
          </div>
          <div fxFlex="100">
            <img src="./assets/images/latest-activity/no884/13.jpg"
                 alt="小孩烤Pizza" style="width:100%; height:auto;"/>
          </div>
        </div>

      </div>
      <div fxFlex="50">
        <div fxFlex="100">
          <img src="./assets/images/latest-activity/no884/16.jpg"
               alt="小孩烤Pizza" style="width:100%; height:auto;"/>
        </div>
      </div>
      <div fxFlex="25">
        <div fxFlexFill fxLayout="column">
          <div fxFlex="100">
            <img src="./assets/images/latest-activity/no884/15.jpg"
                 alt="小孩烤Pizza" style="width:100%; height:auto;"/>
          </div>
          <div fxFlex="100">
            <img src="./assets/images/latest-activity/no884/14.jpg"
                 alt="小孩烤Pizza" style="width:100%; height:auto;"/>
          </div>
        </div>


      </div>
    </div>
  </div>
  <div fxFlex="auto">
    <div style="margin-top: 10px; margin-bottom:10px;">
      <span class="article-subscript-title" style="background:#A3412A;">【生態展覽 X 剪紙藝術】</span>
    </div>
    <div class="article-subscript-content">
      <img src="./assets/images/latest-activity/no884/17.jpg"
           style="height:auto; float: right; margin:10px;"
           [ngClass]="'img-20'"
           [ngClass.sm]="{'img-sm': true}"
           [ngClass.xs]="'img-xs'"
           alt="小孩吃披薩">
      在一整個早上的講解及淨山活動後，也讓隊員休息一下，進到清水岩生態展示中心，欣賞數百種昆蟲標本，許多都是「八卦山脈保育協會」前理事長張宏之老師的收藏捐贈展示，也是對八卦山地區生態保育教育做了完整紀錄。隊員們除了仔細觀賞世界最大品種的蛾、以及國內外特殊的貓頭鷹、蝴蝶等標本，館內也提供昆蟲剪紙給孩子們體驗，看到孩子們仔細的將白色區域一一剪下，展開來就是一幅美麗的剪紙藝術，值得細細收藏。
    </div>
  </div>
  <div fxFlex="auto">
    <div fxFlexFill
         fxLayout="row"
         fxLayout.sm="column"
         fxLayout.xs="column"
         fxLayoutGap="1%"
    >
      <div fxFlex="35">
        <div fxLayout="column">
          <div fxFlex="100">
            <img src="./assets/images/latest-activity/no884/18.jpg"
                 alt="小孩烤Pizza" style="width:100%; height:auto;"/>
          </div>
          <div fxFlex="100">
            <img src="./assets/images/latest-activity/no884/19.jpg"
                 alt="小孩烤Pizza" style="width:100%; height:auto;"/>
          </div>
        </div>
      </div>

      <div fxFlex="35">
        <div fxLayout="column">
          <div fxFlex="100">
            <img src="./assets/images/latest-activity/no884/20.jpg"
                 alt="小孩烤Pizza" style="width:100%; height:auto;"/>
          </div>
          <div fxFlex="100">
            <img src="./assets/images/latest-activity/no884/21.jpg"
                 alt="小孩烤Pizza" style="width:100%; height:auto;"/>
          </div>
        </div>
      </div>
      <div fxFlex="30">
        <div fxFlex="100">
          <img src="./assets/images/latest-activity/no884/22.jpg"
               alt="小孩烤Pizza" style="width:100%; height:auto;"/>
        </div>
      </div>

    </div>
  </div>

  <div fxFlex="auto">
    <div style="margin-top: 10px; margin-bottom:10px;">
      <span class="article-subscript-title" style="background:#6C8448;">【校園服務隊社會服務明年見】</span>
    </div>
    <div class="article-subscript-content">
      古道淨山、生態解析、古蹟巡禮、標本展覽、剪紙藝術，多元又豐富的行程讓每位校園服務隊的隊員收穫滿滿，活動尾聲當然要好好犒賞一下辛苦的隊員們，特地準備了在地食材做成的「山中傳奇鳳梨酥」及「社頭芭樂冰棒」，看到每位隊員滿足的表情就知道，今天的活動一定會帶給孩子們難忘的回憶，更希望透過這樣的體驗，讓未來的主人翁都能與大自然共存、學習並欣賞大自然的美好，校園服務隊社會服務，明年見。
    </div>
  </div>
  <div fxFlex="auto">
    <div fxLayout="row"
         fxLayout.sm="column"
         fxLayout.xs="column"
         fxFlexFill fxLayoutGap="1%"
    >
      <div fxFlex="25">
        <img src="./assets/images/latest-activity/no884/23.jpg"
             alt="小孩烤Pizza" style="width:100%; height:auto;"/>
      </div>
      <div fxFlex="25">
        <img src="./assets/images/latest-activity/no884/24.jpg"
             alt="小孩烤Pizza" style="width:100%; height:auto;"/>
      </div>
      <div fxFlex="25">
        <img src="./assets/images/latest-activity/no884/25.jpg"
             alt="小孩烤Pizza" style="width:100%; height:auto;"/>
      </div>
      <div fxFlex="25">
        <img src="./assets/images/latest-activity/no884/26.jpg"
             alt="小孩烤Pizza" style="width:100%; height:auto;"/>
      </div>
    </div>
  </div>
</div>
