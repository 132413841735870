<h2 mat-dialog-title>英語創意科學</h2>
<mat-dialog-content class="mat-typography">

  <h2>2022 英語創意科學博覽會-疫情停辦</h2>

  <a href="https://www.fhbes.tc.edu.tw/latest_activity/878" target="_blank">
    <h2>2020英語創意科學博覽會</h2>
  </a>

  <a href="https://www.fhbes.tc.edu.tw/latest_activity/877" target="_blank">
    <h2>2020英語創意科學博覽會</h2>
  </a>

  <a href="https://www.fhbes.tc.edu.tw/latest_activity/596" target="_blank">
    <h2>2016英語創意科學博覽會</h2>
  </a>

  <a href="https://www.fhbes.tc.edu.tw/latest_activity/454" target="_blank">
    <h2>2014英語創意科學博覽會</h2>
  </a>

  <a href="https://www.fhbes.tc.edu.tw/latest_activity/217" target="_blank">
    <h2> 2012 外語部自然科學展熱鬧登場!</h2>
  </a>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close (click)="closeDialog()">關閉</button>
</mat-dialog-actions>

