import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material.module';
import { LatestActivityThemeComponent } from './latest-activity-theme/latest-activity-theme.component';
import { FlexModule } from '@angular/flex-layout';
import { HallListThemeComponent } from './hall-list-theme/hall-list-theme.component';



@NgModule({
  declarations: [LatestActivityThemeComponent, HallListThemeComponent],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    FlexModule
  ]
})
export class ThemeModule { }
