<!-- <div fxFlexFill class="container" fxLayout="column" fxLayoutGap="16px">
  <div class="title-container">
    <h1 class="title-ch-font">20周年校慶運動會系列活動</h1>
    <h2 class="title-substandard-font">【歡迎東京奧運空手道銅牌 - 文姿云來校演講】</h2>
  </div>
  
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="16px">
    <div fxFlex="40" class="title-img responsive-img-container">
      <img src="assets/images/latest-activity/no1129/image002.png" alt="title img"/>
    </div>
    
    <div fxFlex="60" class="intro-text article-subscript-content">
      <span>
        明道普霖斯頓20周年校慶即將到來，學校特別邀請到東京奧運空手道銅牌得主 - 文姿云來校演講，姿云姐姐以生命的熱情分享著前進奧運的榮耀與甘苦，台灣之光的故事激勵著全體師生，同時也對應著明道普霖斯頓20年來的堅持與努力，更期待未來Future Heir能培育更多優秀學子站上世界的舞台!
      </span>
    </div>
  </div>
  
  <div class="article-subscript-content" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="16px">
    <div fxFlex="60" class="text-content">
      在全校師生的歡呼下，姿云姐姐站在舞台上，充滿熱情地與同學們分享著對於空手道的喜愛與投入，雖然在訓練過程中，她曾面臨過無數次的失敗與挫折，但頻藉著對空手道的喜歡，她一次次突破難關，從失敗中找到力量，不放棄對自己的信念和夢想，姿云姐姐分享著<strong>『相信自己就是最大的超能力!』</strong>終於在東京奧運上奪得銅牌，為台灣爭光的心路歷程感動著現場師生，大家無不被充滿正能量姿云姐姐鼓舞激勵著!
    </div>
    <div fxFlex="40" class="image-container" fxLayout="column" fxLayoutGap="8px">
      <div class="responsive-img-container">
        <img src="assets/images/latest-activity/no1129/image003.jpg" alt="文姿云演講" class="full-width-image"/>
      </div>
      <div fxLayout="row" fxLayoutGap="8px">
        <div fxFlex="50">
          <img src="assets/images/latest-activity/no1129/image005.png" alt="文姿云演講" class="full-width-image"/>
        </div>
        <div fxFlex="50">
          <img src="assets/images/latest-activity/no1129/image007.png" alt="文姿云與學生互動" class="full-width-image"/>
        </div>
      </div>
    </div>
  </div>

  <div class="article-subscript-content">
    <img src="assets/images/latest-activity/no1129/image009.png"
         style="height:auto; float: left; margin:0 10px 10px 0;"
         [ngClass]="'img-25'"
         [ngClass.sm]="{'img-sm': true}"
         [ngClass.xs]="'img-xs'"
         alt="創辦人與文姿云切磋武藝">
    演講結束後，黃啟誠創辦人化身為空手道高手驚喜現身，與文姿云姐姐現場切磋武藝。黃啟誠創辦人在孩子們的引頸期盼中擊破野餐桌與門板，搞笑演出讓現場氣氛達到了高潮，而文姿云姐姐更展現奧運實力，雙向擊破木板，俐落的身手贏得大家的掌聲與尖叫!
    <p>在熱烈的掌聲中，文姿云姐姐、黃啟誠創辦人、陳榮富校長與劉原旭副校長一起站上舞台，開啟禮物箱，一份時光的禮物 - 時空膠囊緩緩升起，象徵著學校20年的成長與發展，陳榮富校長劉原旭副校長揭開了彩球 - <strong>『歡慶二十造佳績 喜迎校慶創未來』</strong>，歡慶的彩帶與字條正式啟動20周年校慶系列活動！祝福明道普霖斯頓20歲生日快樂! 未來讓我們繼續勇敢追求夢想，永不言棄!</p>
  </div>
</div> -->
<div fxFlexFill class="container" fxLayout="column" fxLayoutGap="16px">
  <div class="video-container">
    <iframe 
      width="560" 
      height="315" 
      src="https://www.youtube.com/embed/Nk1GbfTbr1A" 
      frameborder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen>
    </iframe>
  </div>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="16px">
    <img src="assets/images/latest-activity/no1129/1129.png" alt="1129-1" class="full-width-image">
    </div>
    
</div>
