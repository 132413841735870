<div fxFlexFill fxLayout="column" class="layout">
  <div class="hall-list-body">
    <div class="hall-list-box">
      <h1>自然科學為主的教育—閃耀的明道普霖斯頓小學之光</h1>
      <p>
        明道普霖斯頓雙語小學以自然科學為主的教育主軸，將科學融入生活化的課程，提昇孩子自己動手做科學的研究能力，已連續十四次獲得臺中市科展國小團體獎冠軍的肯定。在全國科展競賽中，連續五年榮獲國小組團體獎，六次受總統親自接見勉勵。除此之外，本校更蟬聯了十三年臺中市太陽能科學創意玩具競賽冠軍。<br>
        面對每一個與科學相關的重大比賽場合，明道普霖斯頓小學總是走在最前端，老師與孩子們會針對時事趨勢以及現代科學的走向，努力研究生活中的各種科學現象，研究歷程可說是相當艱辛，必須花費許多時間、心力與資源，才能在一次次的嘗試與挑戰中，連續完成這些不可能的任務!<br>
        在每年科學教育界的盛事--全國科展的競賽中，師生們不斷締造新紀錄，本校獲獎作品也具深度及廣度，涵蓋了數學、物理科及生活與應用科學等科別。在數學科方面，第59屆即以「形中有形」--縝密的研究分析與實用性題材打敗各縣市高手，獲得全國第一名；物理科則以「真不是蓋的，誰能比我會飛!」一個簡單的踢塑膠蓋動作引發出研究影響飛行遠近距離的物理原理奪得第60屆物理科全國第二名。<br>
        在生活與應用科學(二)組別中，師生的優異表現更勇奪「五次」全國之冠！<br>
        第56屆「真的全自動-全球免設定日光追蹤系統」<br>
        第58屆「海洋救星─水中漂浮垃圾回收桶」<br>
        第61屆「豆在土中戲–土耕豆芽的可行性與方法流程設計」<br>
        第62屆「均濕」的妙計－等壓出水等量裝置<br>
        第63屆 這「炭」有「種」-自製無煙速燃炭粉餅結合火種功能的可行性<br>
        孩子們能以對社會有意義與貢獻的獨特研究主題，獲得全國第一名的最高榮譽，且在面對種種挑戰也能毫不畏懼向前邁進，科展團隊全體師生一同體現生活中處處有自然，實為閃耀的明道普霖斯頓小學之光！<br>
      </p>
    </div>
    <div class="hall-list-box">
      <h1>科學即生活，用熱情深耕科學教育</h1>
      <p>
        明道普霖斯頓小學的學生快樂又幸福！<br>
        黃啟誠創辦人自創校以來，在自然科學教育上的深耕與堅持，鼓勵師生們將每一個創意發想化為實作，讓生活處處是科學，也讓科學處處有樂趣，印證本校的教育主軸--「自然科學為主」的核心概念，更長期致力於科學教育，才能在每一次的市級或全國科學競賽中屢獲佳績，不斷的締造新紀錄，我們始終相信，只要給孩子舞台，孩子真的可以做得到！<br>
        設備完善的自然科學實驗室，讓孩子喜歡玩科學，進而喜歡上自然課，中英文自然課程的領域整合，讓學生們的好奇心充分獲得滿足。課後的自然探索隊也定期安排生態體驗活動，讓孩子能親近自然環境與了解動植物生態，並涵養孩子們的科學探究精神與態度，讓這群小小科學家成為未來的科學實踐家。<br>
        誠摯與您分享我們的榮耀與喜悅，我們更期許自己，持續用優質的教育及熱忱，與孩子們共同成長。<br>
      </p>
    </div>
  </div>

  <div class="hall-show-title">
    <h1>與您分享明道普霖斯頓小學科學教育歷年來的輝煌榮耀：</h1>
  </div>

  <div fxLayout="wrap row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="1rem">
    <!-- <mat-card fxFlex="50" fxFlex.sm="1000" fxFlex.xs="100"> -->

    <mat-card fxFlex="1 1 calc(50% - 1rem)" fxFlex.sm="0 1 calc(100% - 1rem)" fxFlex.xs="0 1 calc(100% - 1rem)"  (click)="openNationalPrimaryand()">
      <div style="height:auto;">
        <img mat-card-image src="/assets/images/hall-list/show-2-2023.jpg" />
        <div class="img-hover-text">
          <h1>National Primaryand  <br />
            and High School Science Fair</h1>
        </div>
      </div>
      <mat-card-header>
        <mat-card-title>
          全國國民中小學科學展覽會
        </mat-card-title>
        <mat-card-subtitle>
          勇奪六次全國第一名！！<br /><br />
          五度榮獲國小組團體獎<br />
          (2018.2019 團體獎全國第一)<br />
          2023年生應科(二) 第五度奪全國之冠<br />
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>

    <mat-card fxFlex="1 1 calc(50% - 1rem)" fxFlex.sm="0 1 calc(100% - 1rem)" fxFlex.xs="0 1 calc(100% - 1rem)" (click)="openTaichungPrimary()">
      <div style="height:auto;">
        <img mat-card-image src="/assets/images/hall-list/show-1-2023.JPG" />
        <div class="img-hover-text">
          <h1>Taichung Primary <br />
            and Secondary School Science Fair</h1>
        </div>
      </div>
      <mat-card-header>
        <mat-card-title>
          臺中市中小學科學展覽會
        </mat-card-title>
        <mat-card-subtitle>
          蟬聯十三次國小組團體獎冠軍<br />
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>

    <mat-card fxFlex="1 1 calc(50% - 1rem)" fxFlex.sm="0 1 calc(100% - 1rem)" fxFlex.xs="0 1 calc(100% - 1rem)" (click)="openSolarPowered()">
      <div style="height:auto;">
        <img mat-card-image src="/assets/images/hall-list/show-3-2023.jpg" />
        <div class="img-hover-text">
          <h1>Taichung City Solar Powered  <br />
             Toy Competition</h1>
        </div>
      </div>
      <mat-card-header>
        <mat-card-title>
          臺中市太陽能科學創意玩具競賽
        </mat-card-title>
        <mat-card-subtitle>
          蟬聯十三屆 冠軍<br />
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>

    <mat-card fxFlex="1 1 calc(50% - 1rem)" fxFlex.sm="0 1 calc(100% - 1rem)" fxFlex.xs="0 1 calc(100% - 1rem)" (click)="openScienceExpo()">
      <div style="height:auto;">
        <img mat-card-image src="/assets/images/hall-list/show-4-2023.jpg" />
        <div class="img-hover-text">
          <h1>Science Expo</h1>
        </div>
      </div>
      <mat-card-header>
        <mat-card-title>
          英語創意科學博覽會
        </mat-card-title>
        <mat-card-subtitle>
          由學生分組選定主題，全程用英語表達研究的成果<br />
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>

    <mat-card fxFlex="1 1 calc(50% - 1rem)" fxFlex.sm="0 1 calc(100% - 1rem)" fxFlex.xs="0 1 calc(100% - 1rem)" (click)="openFunScience()">
      <div style="height:auto;">
        <img mat-card-image src="/assets/images/hall-list/show-5.png" />
        <div class="img-hover-text">
          <h1>Fun Science</h1>
        </div>
      </div>
      <mat-card-header>
        <mat-card-title>
          趣味科學
        </mat-card-title>
        <mat-card-subtitle>
          學生們透過動腦並發揮創意，製作知識與趣味兼具的科學遊戲，達到玩科學的學習目的。<br />
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>

    <mat-card fxFlex="1 1 calc(50% - 1rem)" fxFlex.sm="0 1 calc(100% - 1rem)" fxFlex.xs="0 1 calc(100% - 1rem)" (click)="openScienceExploration()">
      <div style="height:auto;">
        <img mat-card-image src="/assets/images/hall-list/show-6-2023.jpg" />
        <div class="img-hover-text">
          <h1>FHBES Science  <br />
            Exploration Team</h1>
        </div>
      </div>
      <mat-card-header>
        <mat-card-title>
          自然探索隊
        </mat-card-title>
        <mat-card-subtitle>
          以探索動植物、大自然以及有趣的科學現象為主要課程，讓學生們在玩中學過程裡喜愛自然課。<br />
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </div>
</div>
