<h2 mat-dialog-title>全國國民中小學科學展覽會</h2>
<mat-dialog-content class="mat-typography">
    <a href="https://www.fhbes.tc.edu.tw/latest_activity/1075" target="_blank">
    <h2>第63屆全國國民中小學科學展覽會 生活與應用科學科 第一名(五度榮獲全國第一)</h2>
  </a>
  <a href="https://www.fhbes.tc.edu.tw/latest_activity/1017" target="_blank">
    <h2>第62屆全國國民中小學科學展覽會生活與應用科學科 第一名(四度榮獲全國第一)</h2>
  </a>
  <a href="https://www.fhbes.tc.edu.tw/latest_activity/954" target="_blank">
    <h2>第61屆全國國民中小學科學展覽會生活與應用科學科 第一名 (三度榮獲全國第一)</h2>
  </a>
  <a href="https://www.fhbes.tc.edu.tw/latest_activity/885" target="_blank">
    <h2>第60屆全國國民中小學科學展覽會 榮獲全國第二名</h2>
  </a>
  <a href="https://www.fhbes.tc.edu.tw/latest_activity/825" target="_blank">
    <h2>第59屆全國國民中小學科學展覽會 榮獲全國第一名</h2>
  </a>

  <a href="https://www.fhbes.tc.edu.tw/latest_activity/754" target="_blank">
    <h2>第58屆全國國民中小學科學展覽會 榮獲全國第一名</h2>
  </a>

  <a href="https://www.fhbes.tc.edu.tw/latest_activity/602" target="_blank">
    <h2>第56屆全國國民中小學科學展覽會 榮獲全國第一名</h2>
  </a>

  <a href="https://www.fhbes.tc.edu.tw/latest_activity/369" target="_blank">
    <h2>2013科展榮獲全國一銀、一銅及全國最佳教材獎</h2>
  </a>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close (click)="closeDialog()">關閉</button>
</mat-dialog-actions>
