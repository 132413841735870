import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LatestActivityRoutingModule } from './latest-activity-routing.module';
import { No881Component } from './no881/no881.component';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { No884Component } from './no884/no884.component';
import { No1129Component } from './no1129/no1129.component';


@NgModule({
  declarations: [No881Component, No884Component, No1129Component],
    imports: [
        CommonModule,
        LatestActivityRoutingModule,
        FlexModule,
        FlexLayoutModule
    ]
})
export class LatestActivityModule { }
