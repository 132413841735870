import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-taichung-primary',
  templateUrl: './taichung-primary.component.html',
  styleUrls: ['./taichung-primary.component.css']
})
export class TaichungPrimaryComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TaichungPrimaryComponent>,

  ) { }

  ngOnInit(): void {
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
