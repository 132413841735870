<div fxFill fxLayout="column" class="layout">
  <div  class="header">
    <mat-toolbar>
      <span>校園活動報導</span>
      <span class="example-spacer"></span>
      <a href="http://www.fhbes.tc.edu.tw/" mat-icon-button class="example-icon" aria-label="Example with share">
        回首頁
      </a>
    </mat-toolbar>
  </div>
  <div fxFlex fxLayout="row" class="main" >
    <div fxFlex="90%" class="content" fxFlexOffset="5" style="max-width:1280px;"
         fxFlex.lg="80%"
         fxFlex.xl="80%"
         fxFlexOffset.lg="10"
         fxFlexOffset.xl="10"
    >
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
