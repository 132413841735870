<div fxFlexFill class="content" >
  <div class="title-img">
    <img src="assets/images/latest-activity/no881/title.jpg" alt="title img"/>
  </div>
  <div class="title-ch-font">
    自然探索隊戶外教學之開心農場 - 大自然的美味廚房
  </div>
  <div  class="title-en-font">
    A Taste of Nature and Gourmet Food Adventure
  </div>
  <div  class="title-substandard-font">
    By: The School Science Exploration Team<br>
    Featuring: Pickled Passion Fruit, Brick Oven Baked Pizza and Coffee Beans from Happy Farm<br>
  </div>
  <hr>
  <div style="font-style: italic; text-indent : 2em ;">
    防疫期間，你是否好久未曾出門感受美好的大自然呢？為了給孩子一個安全又充滿驚喜的戶外體驗，創辦人思量許久，決定在開心農場上搭建一個披薩窯！加上農場裡豐富又天然的作物，一個專屬於明道普霖斯頓的美味廚房就此開張！你是否也聞到濃濃的柴窯披薩香味呢？一起跟著自然探索隊員來場深度的開心農場美食之旅吧！
  </div>
  <br/>
  <div>
    <span class="article-subscript-title">【石窯的蓄熱秘密Ｘ手『感』麵團Ｘ發酵的科學】</span>
  </div>
  <div class="article-subscript-content">
    <img src="assets/images/latest-activity/no881/01.jpg"
         style=" max-width: 30%; height:auto; float: right; margin:10px 20px 20px;" alt="創辦人烤披薩"/>
    一走上農場斜坡，孩子們紛紛發出驚呼聲，原來是剛剛完成的披薩窯，驚喜的是--可愛的貓咪造型正張大嘴巴，等著披薩上門呢~
    <img src="./assets/images/latest-activity/no881/02.jpg"
         style=" max-width: 30%; height:auto; float: left; margin: 20px;" alt="小孩吃披薩">

    自然老師講解起窯烤披薩和麵包好吃的原理，窯烤爐的圓頂內層能蓄熱耐高溫，產生的熱對流與遠紅外線能包裹食材，使其快速均勻受熱，鎖住水分，讓表面酥脆，更能瞬間濃縮食材的美味，說到這每位隊員都不禁吞起口水，超級期待親手揉製麵糰的時刻，而好吃的餅皮需要正確的配方才能成功，理解發酵的原理便十分重要，原來酵母菌會將糖轉化為二氧化碳和酒精，麵團就是在此時，從無生命物質變成具有生命力的有機體，而『好吃』靠的就是「控制」時間和溫度，如何根據外部環境做出揉麵和發酵時間的調整，便是烘焙工作最有趣、最具挑戰性的部分！<br>
    <div style="text-align:center; float: left;">
      <img src="./assets/images/latest-activity/no881/03.jpg"
           style="max-width:34%; height:auto; float:left; margin:10px;" alt="小孩烤Pizza"/>
      <img src="./assets/images/latest-activity/no881/04.jpg"
           style="max-width:34%; height:auto; float:left; margin:10px;" alt="小孩烤Pizza"/>
      <img src="./assets/images/latest-activity/no881/05.jpg"
           style="max-width:20%; height:auto; float:left; margin:10px;" alt="小孩烤Pizza"/>
    </div>
    <div>
      為了作出好吃的披薩，隊員們仔細地測量所需的物料，分批加入、仔細攪拌、細心搓揉、用力耍打，耐心等待麵團長大，在溫暖的天氣的協助下，每顆麵團都變成白白胖胖、鬆鬆軟軟，再拍成餅皮，加上豐富的新鮮食材、撒上超濃起司，此時的披薩窯在創辦人與主任的照料下，已到達近300度的高溫，孩子拿起鏟子將親手做的披薩送入窯中，火力強大的窯爐只需5分鐘就烤好6片披薩，等不及放涼，孩子們便滿心期待地分切入口，哇~真的是太好吃啦！
    </div>

  </div>
  <div style="margin-top: 10px; margin-bottom:10px;">
    <span class="article-subscript-title" style="background:#5075A7;">【手採百香果X醃漬的科學】</span>
  </div>
  <div class="article-subscript-content">
    <img src="./assets/images/latest-activity/no881/06.jpg"
         style="max-width:20%; height:auto; float:right; margin:10px;" alt="小孩烤Pizza"/>
    <img src="./assets/images/latest-activity/no881/07.jpg"
         style="max-width:20%; height:auto; float:right; margin:10px;" alt="小孩烤Pizza"/>

    充滿秘境的開心農場，有一區種植著百香果的秘密基地，結實累累的滿天星百香果一顆顆地掛在棚架上，自然老師介紹著百香果變酸的原理，原來只要百香果一經碰撞或者低溫就會變酸，為此許多農友都會搭建網子接住成熟的落果，今天每位探索隊員都可以親手摘下香甜的滿天星百香果，與青木瓜一起醃漬成美味的食品，醃漬是時間的科學，用鹽搓揉可以去除青木瓜的苦澀味，透過簡單的滲透作用使食物脫水，讓糖分子滲入木瓜裡達到平衡，孩子們從削皮、分切、搓揉全部自己來，一道消暑開胃又酸甜可口的美食便完成囉~
  </div>

  <div style="margin-top: 10px; margin-bottom:10px;">
    <span class="article-subscript-title" style="background:#A3412A;">【手烘咖啡豆X香味的科學】</span>
  </div>
  <div class="article-subscript-content">
    <img src="./assets/images/latest-activity/no881/08.jpg" style="max-width:40%; height:auto; float:left; margin:10px;"
         alt="小孩烤Pizza"/>
    在開心農場的小徑上，有幾棵咖啡樹上正滿是紅的、黑的、綠的咖啡豆。老師帶著孩子們品嘗起新鮮的咖啡果實，這才發現原來咖啡果實是香甜的，原來咖啡豆外層有果膠，與平時大家印象中苦苦又香香的咖啡豆不同，老師準備了生咖啡豆讓孩子們嘗試烘烤，一陣陣香味慢慢地撲鼻而來，原來這是咖啡獨特的硫化物質，經過火烤後後產生巧妙的化學變化，便不斷的產生香氣，不同的烘烤程度也會產生不一樣的香味喔！孩子們帶著親手烘烤的咖啡豆與爸媽分享，還品嘗了
    現煮的咖啡牛奶，山上微風徐徐吹來，真是美好的農場生活啊！
  </div>
  <div style="margin-top: 10px; margin-bottom:10px;">
    <span class="article-subscript-title" style="background:#6C8448;">【美食獵人的山中秘徑】</span>
  </div>
  <div class="article-subscript-content">
    物產豐饒的開心農場，到處結實累累，紅澄澄的特殊品種—玫瑰荔枝、尚未轉黃的筆柿、圓滾滾的柚子、是橘子還是柳丁？….
    ，新鮮水果的香氣縈繞在農場四處，老師們帶著隊員邊吃邊介紹，感受大自然的恩典，體會大自然與人類互利共生的概念，更化身美食獵人一起探訪了從未走過的農場秘徑，可以食用的野草、野菜、有毒的姑婆芋就在你我身邊，還撿拾可愛的小西氏石礫果實，發現了從未體驗過的開心農場，這一天真是如此美好，讓我們永遠不要忘記這專屬於自然探索隊的回憶。
  </div>
  <img src="./assets/images/latest-activity/no881/09.jpg" style="max-width:100%; text-align:center; margin-top:10px;" alt="大合照"/>
</div>
