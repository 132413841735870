import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { No881Component} from './no881/no881.component';
import { No884Component} from './no884/no884.component';
import { No1129Component } from './no1129/no1129.component'; // 新增這行

const routes: Routes = [
  { path: '881', component: No881Component},
  { path: '884', component: No884Component},
  { path: '1129', component: No1129Component}, // 新增這行
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LatestActivityRoutingModule { }
