import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { HallListRoutingModule } from './hall-list-routing.module';
import { ScienceFairsComponent } from './science-fairs/science-fairs.component';
import { MaterialModule } from '../material.module';
import { NationalPrimaryandComponent } from './science-fairs/national-primaryand/national-primaryand.component';
import { TaichungPrimaryComponent } from './science-fairs/taichung-primary/taichung-primary.component';
import { SolarPoweredComponent } from './science-fairs/solar-powered/solar-powered.component';
import { ScienceExpoComponent } from './science-fairs/science-expo/science-expo.component';
import { FunScienceComponent } from './science-fairs/fun-science/fun-science.component';
import { ScienceExplorationTeamComponent } from './science-fairs/science-exploration-team/science-exploration-team.component';

@NgModule({
  declarations: [
    ScienceFairsComponent,
    NationalPrimaryandComponent,
    TaichungPrimaryComponent,
    SolarPoweredComponent,
    ScienceExpoComponent,
    FunScienceComponent,
    ScienceExplorationTeamComponent],
  imports: [
    MaterialModule,
    CommonModule,
    HallListRoutingModule,
    FlexModule,
    FlexLayoutModule
  ]
})
export class HallListModule { }
