<h2 mat-dialog-title>趣味科學</h2>
<mat-dialog-content class="mat-typography">
  <a href="https://www.fhbes.tc.edu.tw/latest_activity/1067" target="_blank">
    <h2>2023 趣味科學(Mouse Trap, Air Rocket & AI Car)</h2>
  </a>

  <a href="https://www.fhbes.tc.edu.tw/latest_activity/953" target="_blank">
    <h2>2021 趣味科學 (AI Car)</h2>
  </a>

  <a href="https://www.fhbes.tc.edu.tw/latest_activity/951" target="_blank">
    <h2>2021 趣味科學 (Mouse Trap & Air Rocket)</h2>
  </a>

  <a href="https://www.fhbes.tc.edu.tw/latest_activity/876" target="_blank">
    <h2>2020 趣味科學 (AI Car)</h2>
  </a>

  <a href="https://www.fhbes.tc.edu.tw/latest_activity/819" target="_blank">
    <h2>2019 趣味科學 (AI Car)</h2>
  </a>

  <a href="https://www.fhbes.tc.edu.tw/latest_activity/660" target="_blank">
    <h2>2017 趣味科學(Mouse Trap & Air Rocket)</h2>
  </a>

  <a href="https://www.fhbes.tc.edu.tw/latest_activity/512" target="_blank">
    <h2>2015 趣味科學(Mouse Trap & Air Rocket)</h2>
  </a>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close (click)="closeDialog()">關閉</button>
</mat-dialog-actions>

