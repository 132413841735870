import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LatestActivityThemeComponent} from './theme/latest-activity-theme/latest-activity-theme.component';
import { HallListThemeComponent } from './theme/hall-list-theme/hall-list-theme.component';
import { TestComponent } from './test/test.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'latest-activity',
        component: LatestActivityThemeComponent,
        loadChildren: () => import('./latest-activity/latest-activity.module').then(m => m.LatestActivityModule),
        data: {title: 'Latest Activity', titleI18n: 'Latest Activity'}
      },{
        path: 'hall-list',
        component: HallListThemeComponent,
        loadChildren: () => import('./hall-list/hall-list-routing.module').then(m => m.HallListRoutingModule),
        data: {title: 'Hall List', titleI18n: 'Hall List'}
      }
    ],
  },
  {path: 'test', component: TestComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
