import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-science-exploration-team',
  templateUrl: './science-exploration-team.component.html',
  styleUrls: ['./science-exploration-team.component.css']
})
export class ScienceExplorationTeamComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ScienceExplorationTeamComponent>,

  ) { }

  ngOnInit(): void {
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
