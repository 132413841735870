import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-latest-activity-theme',
  templateUrl: './latest-activity-theme.component.html',
  styleUrls: ['./latest-activity-theme.component.css']
})
export class LatestActivityThemeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
