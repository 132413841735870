import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NationalPrimaryandComponent } from './national-primaryand/national-primaryand.component';
import { TaichungPrimaryComponent } from './taichung-primary/taichung-primary.component';
import { SolarPoweredComponent } from './solar-powered/solar-powered.component';
import { ScienceExpoComponent } from './science-expo/science-expo.component';
import { FunScienceComponent} from './fun-science/fun-science.component';
import { ScienceExplorationTeamComponent } from './science-exploration-team/science-exploration-team.component';

@Component({
  selector: 'app-science-fairs',
  templateUrl: './science-fairs.component.html',
  styleUrls: ['./science-fairs.component.css']
})
export class ScienceFairsComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  openNationalPrimaryand() {
    this.dialog.open(NationalPrimaryandComponent, { width: '80%'});

  }

  openTaichungPrimary(){
    this.dialog.open(TaichungPrimaryComponent, { width: '80%'});

  }

  openSolarPowered(){
    this.dialog.open(SolarPoweredComponent, { width: '80%'});
  }

  openScienceExpo(){
    this.dialog.open(ScienceExpoComponent, { width: '80%'});

  }

  openFunScience(){
    this.dialog.open(FunScienceComponent, { width: '80%'});
  }

  openScienceExploration(){
    this.dialog.open(ScienceExplorationTeamComponent, { width: '80%'});

  }
}
